/* title */

.title_container {
   position: relative;
   width: fit-content;
   margin: auto;
}

.title {
   line-height: 120px;
}

.title_bar {
   width: 100%;
   height: 20px;
   background: #08B118;
   position: absolute;
   top: 95px;
   z-index: -1;
}

/* header */
.header {
   margin: 10px 0px 30px 0px;
}



/* navbar */
.navbar_container {
   flex-wrap: wrap;
   justify-content: center;
}


.nav_button {
   padding: 0 5px;
}

.nav_name {
   font-size: 32px;
   cursor: pointer;
}

.circle_container {
   padding: 0 10px;
}

.circle_container .circle {
   width: 10px;
   height: 10px;
   background: linear-gradient(180deg, #08B118 0%, #29B92A 100%);
   border-radius: 5px;
}

.menu_container {
   position: relative;
}

.menu_navbar_container {
   position: absolute;
}



/* sticky nav */
.sticky_navbar_container {
   position: fixed;
   display: flex;
   flex-direction: row;
   justify-content: center;
   top: -10px;
   width: 100%;
   z-index: 3;
}

.sticky_background {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   padding: 25px 20px 20px 20px;
   background-color: #F2FEF2;
   border-radius: 5px;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.display_none {
   display: none;
}



/* golink */
.link_out_container {
   padding: 0 20px;
}


.link_in_container img {
   width: 45px;
   height: 45px;
}



/* github */
.github_out_container {
   padding: 0 20px;
}


.github_in_container img {
   height: 35px;
}

/* Arrows */

.rarrow_container {
   width: 50px;
   height: 50px;
}

.rarrow_container img {
   width: 48px;
   height: 48px;
}

.larrow_container {
   width: 50px;
   height: 50px;
}

.larrow_container img {
   width: 48px;
   height: 48px;
}



/* dropdown */
.dropdown_container {
   width: fit-content;
   display: flex;
   align-items: center;
   margin-top: 20px;
}

.dropdown_title {
   height: fit-content;
   padding: 0 20px;
}

.project_desc_container {
   padding: 0;
   opacity: 0;
   height: 0;
   position: relative;
   z-index: -1;
}

.project_desc_container.active {
   padding: 10px 0 20px 100px;
   opacity: 1;
   height: 100%;
}

/* project */

.project_container {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-top: 40px;
}

.project_title {
   padding: 0 20px;
}

.read_more_container {
   display: flex;
   flex-direction: row;
   cursor: pointer;
}

.category_container {
   padding-top: 50px;
   padding-bottom: 50px;
}

.desc_container {
   padding-left: 20px;
   padding-top: 15px;
}

.project_bar {
   height: 100%;
   width: 10px;
   position: absolute;
   left: -17px;
   top: 0px;
   background-color: #4CC946;
}


/* blog */
.blog_title {
   margin-bottom: 20px;
}


/* margin container */

.margin_container {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 5fr 1fr;
   grid-template-rows: auto;
}

.margin_container_margin {
   width: 256px;
   min-width: 70px;
}

/* .margin_container .content {
   max-width: 1000px; 
   flex-grow: 2;
} */

/* .margin_left {
   width: 256px;
}

.margin_right {
   width: 256px;
} */