.padding_module {
   margin-top: 150px;
   margin-bottom: 50px;
   padding: 20px 0 100px 0;
   grid-column: 2 / span 1;
}

.mobile_padding_module {
   margin-top: 50px;
   margin-bottom: 50px;
   padding: 20px 0 20px 0;
   grid-column: 2 / span 1;
}


/* title */
.title_page_container {
   display: grid;
   grid-template-columns: 1fr 5fr 1fr;
   grid-template-rows: 2fr 2fr 2fr 1fr 1fr 1fr 1fr;
   width: 100%;
}


.non_mobile_title_page {
   height: 100vh;
}

.mobile_title_page {
   margin: auto;
   grid-template-columns: 0;
   grid-template-rows: 0;
   margin-top: 50px;
}


.middle_content {
   text-align: center;
   grid-column: 2 / span 1;
   grid-row: 2 / span 1;
}

.nav_bar {
   grid-column: 2 / span 1;
   grid-row: 4 / span 1;
}

.welcome_container {
   margin: 20px;
}

.bottom_margin {
   grid-column: 2 / span 1;
   grid-row: 6 / span 1;
   display: flex;
   justify-content: center;
}


.doubledown_container img {
   width: 25px;
}

canvas {
   position: absolute;
}


/* about */

.about_container {
   position: relative;
}

.about_bar {
   background: linear-gradient(180deg, #08B118 0%, #29B92A 100%);
}

.about_para {
}


/* featured */

.featured_container {
   position: relative;
}

.featured_bar {
   background: #29B92A;
}



/* blog */

.blog_bar {
   background: linear-gradient(180deg, #29B92A 0%, #3CC139 100%);
}



/* resume */
.google_docs_container {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 20px;
}


.resume_bar {
   background: linear-gradient(180deg, #3CC139 0%, #4CC946 100%);
}



/* projects */

.projects_bar {
   background: #3CC139;
}



/* archive */
.archive_bar {
   background-color: #5AD253;
}


/* contact */

.contact_bar {
   background: linear-gradient(180deg, #5AD253 0%, #67DA60 100%);
}

.contact_section_container {
   padding-top: 20px;
   align-items: center;
}

.contact_logo_container img {
   width: 60px;
}

.contact_logo_container {
   padding: 10px;
}

.info {
   padding-left: 20px;
}

/* footer */

.footer_container {
   grid-column: 2 / span 1;
}

.footer_text {
   position: relative;
   bottom: 20px;
}