/*Good*/

body {
  font-family: 'Nanum Myeongjo', serif;
  font-style: normal;
  font-weight: 400;
  background: #F2FEF2;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: #29B92A;
}

/* font sizes */

.title_size {
  font-size: 96px;
  line-height: 120px;
}

.header_size {
  font-size: 64px;
  line-height: 80px;
}

.demi_header_size {
  font-size: 48px;
  line-height: 60px;
}

.sub_header_size {
  font-size: 36px;
  line-height: 45px;
}

.normal_text_size {
  font-size: 32px;
  line-height: 40px;
}

.small_text_size {
  font-size: 20px;
  line-height: 25px;
}


.mobile_title_size {
  font-size: 64px;
  line-height: 80px;
}

.mobile_header_size {
  font-size: 48px;
  line-height: 60px;
}

.mobile_demi_header_size {
  font-size: 36px;
  line-height: 45px;
}

.mobile_sub_header_size {
  font-size: 28px;
  line-height: 36px;
}

.mobile_normal_text_size {
  font-size: 20px;
  line-height: 25px;
}

.mobile_small_text_size {
  font-size: 15px;
  line-height: 20px;
}


/* bar css */

.vert_bar {
  height: 100%;
  width: 20px;
  position: absolute;
  left: -44px;
  top: 0px;
}

.horiz_bar {
  width: 750px;
  height: 20px;
  position: relative;
  top: -30px;
}

.mobile_horiz_bar {
  height: 20px;
  width: auto;
  position: relative;
  top: -30px;
}

.for_the_bar {
  position: relative;
  padding-bottom: 20px;
}


/* button */

.sixty_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
}

.mobile_sixty_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.eighty_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
}

.mobile_eighty_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
}

/* button animation creadit to spankiejoe - https://codepen.io/spankiejoe/pen/qdRpaW */


.button {
  background: rgba(242, 254, 242, 0.1);
  position: relative;
  border-radius: 50%;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  transition: background 0.5s;
}

.button:hover {
  background: rgba(191, 204, 184, 1);
}

.button:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.button:after {
  top: -5px;
  left: -5px;
  padding: 5px;
  box-shadow: 0 0 0 4px #bfccb8;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(.8);
  -ms-transform: scale(.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(.8);
  opacity: 0;
}

.button:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 1a */


.button.effect-1.sub-a:hover i {
  color: #41ab6b;
}



/* flexboxes */

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.align_center {
  align-items: center;
}

.justify_center {
  justify-content: center;
}


/* elements */
.canvas_container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: -2;

  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}